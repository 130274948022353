import { useTranslation } from 'react-i18next';

import SEO from 'components/seo';
import MaintenanceIcon from 'components/icons/MaintenanceIcon';
import WriteTo from 'components/write-to/write-to';
import { MainContentArea, MaintenanceWrapper } from 'assets/styles/pages.style';

export default function MaintenancePage() {
  const { t } = useTranslation();

  return (
    <MainContentArea>
      <MaintenanceWrapper size={2}>
        <MaintenanceIcon />
        <h2>{t('pageMaintenance.title')}</h2>
        <p>{t('pageMaintenance.text')}:</p>
        <WriteTo />
      </MaintenanceWrapper>
    </MainContentArea>
  );
}

export function Head() {
  const { t } = useTranslation();
  return <SEO title={t('pageTitles.maintenance.title')} />;
}
