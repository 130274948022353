const ComingSoonIcon = () => {
  return (
    <svg
      width="164"
      height="164"
      viewBox="0 0 164 164"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M93.291 0H14.4141C6.45334 0 0 6.45334 0 14.4141V43.2422C0 45.8957 2.15122 48.0469 4.80469 48.0469H80.0781L93.291 0Z"
        fill="#BDCAF8"
      />
      <path
        d="M149.586 0H93.291L76.5054 40.2854C75.7593 42.0759 74.0101 43.2422 72.0703 43.2422H0V149.586C0 157.547 6.45334 164 14.4141 164H69.1875L82 161.438L94.8125 164H149.586C157.547 164 164 157.547 164 149.586V14.4141C164 6.45334 157.547 0 149.586 0Z"
        fill="#E0E7FF"
      />
      <path
        d="M24.0234 26.4258C26.677 26.4258 28.8281 24.2746 28.8281 21.6211C28.8281 18.9675 26.677 16.8164 24.0234 16.8164C21.3699 16.8164 19.2188 18.9675 19.2188 21.6211C19.2188 24.2746 21.3699 26.4258 24.0234 26.4258Z"
        fill="white"
      />
      <path
        d="M43.2422 26.4258C45.8957 26.4258 48.0469 24.2746 48.0469 21.6211C48.0469 18.9675 45.8957 16.8164 43.2422 16.8164C40.5886 16.8164 38.4375 18.9675 38.4375 21.6211C38.4375 24.2746 40.5886 26.4258 43.2422 26.4258Z"
        fill="white"
      />
      <path
        d="M62.4609 26.4258C65.1145 26.4258 67.2656 24.2746 67.2656 21.6211C67.2656 18.9675 65.1145 16.8164 62.4609 16.8164C59.8074 16.8164 57.6562 18.9675 57.6562 21.6211C57.6562 24.2746 59.8074 26.4258 62.4609 26.4258Z"
        fill="white"
      />
      <path
        d="M98.7937 60.8058C95.603 58.9596 91.6097 61.2962 91.6097 64.9824V85.205H72.3906V64.9824C72.3906 61.2962 68.3973 58.9596 65.2067 60.8058C55.1488 66.6259 48.3672 77.5031 48.3672 89.9357C48.3672 97.3762 50.7487 104.428 55.2539 110.329C58.8629 115.056 63.7233 118.772 69.1875 121.035V164.002H94.8125V121.035C100.277 118.772 105.137 115.055 108.746 110.328C113.251 104.428 115.633 97.3759 115.633 89.9354C115.633 77.5028 108.851 66.6259 98.7937 60.8058Z"
        fill="white"
      />
    </svg>
  );
};

export default ComingSoonIcon;
