import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
`;

export const StyledListItem = styled.li`
  a {
    display: flex;
    align-items: center;
    color: ${themeGet('colors.primary.regular')};

    svg {
      margin-right: 13px;
    }
  }

  &:not(:first-child) {
    margin-left: 18px;
  }
`;
